//
// Send contact form API
//

export default class ContactFormAPI {
  constructor( endpoint ) {
    this.endpoint = endpoint;
  }

  submit(formData, success = function(){}, error = function(){} ) {
    var request = $.ajax({
      url: this.endpoint,
      method: "POST",
      data: formData,

      // These are required in order to be able to send files in AJAX requests using FormData
      processData: false,
      contentType: false
    })

    request.done((data) => {
      success(data)
    });

    request.fail((jqXHR, textStatus, errorThrown) => {
      if ( jqXHR && jqXHR.status === 400) {
        error(jqXHR.responseText);
      } else {
        error();
      }

      // Fetch a new repcatcha token. This method is defined in
      // kairos/templates/captcha/includes/js_v3.html
      if ( window.updateCaptcha ) {
        window.updateCaptcha();
      }
    });

    return request;
  }
}
