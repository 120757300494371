import $ from 'jquery';

import ContactFormAPI from './api/contact-form';
import { isConfiguratorMode } from './utils/configurator';

$(function () {

  $(document).on('submit', 'form.contact', function(e) {
    // don't submit contact forms if in configurator
    if (isConfiguratorMode()) {
      return false;
    }

    var $form = $(this),
        formData = new FormData(this),
        $btnSubmit = $form.find('.btn[type="submit"]');

    let api = new ContactFormAPI($form.attr('action'));
    api.submit(formData,
      // Success
      (data) => {
        let $section = $form.closest('section.contact-form');
        $section.addClass('pt-5').html(data);
        $section.get(0).scrollIntoView(false);
      },
      // Error
      (errorMessage) => {
        // Re-enable form
        $btnSubmit.removeAttr('disabled')
                  .html($btnSubmit.data('original-content'));

        // Show a generic error message if nothing was received from the server
        if ( !errorMessage ) {
          errorMessage = "A apărut o eroare neașteptată, te rugăm să reîncerci.";
        }

        // Show the error message
        $btnSubmit.parent()
                  .find('.alert').remove();
        $btnSubmit.parent()
                  .prepend('<div class="alert alert-danger mb-3 mt-2" role="alert">' + errorMessage + '</div>');
      });

    // Add a spinner
    $btnSubmit.attr('disabled', 'disabled')
              .data('original-content', $btnSubmit.html())
              .html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;&nbsp; Se trimite mesajul... nu închide pagina');

    // Return false so that the form submit is not executed by the browser
    return false;
  });
});
